
<template>
    

    <v-card
    class="mb-12"
    height="500px"
    >
    
        <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pa-4 pt-6"
            >
                <v-container>
                <v-row>
                    <v-col :cols="12">
                        <v-text-field
                            ref="name"
                            v-model="item.name"
                            :rules="[rules.required]"
                            label="Name"
                            style="min-height: 96px">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="8">
                    <v-text-field
                        v-model="item.area_ha"
                        :disabled="item.area_ha_calculated"
                        
                        min="0.001"
                        label="Fläche/ha"
                        style="min-height: 96px"
                        type="number">
                    </v-text-field>
                    </v-col>
                    <v-col :cols="4">
                        <v-checkbox
                            v-model="item.area_ha_calculated"
                            label="Fläche aus Karte berechnet"
                            @change="checkboxChanged"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="12"> 
                        <v-select
                                v-model="selectCultivationSpecies"
                                :items="cultivationSpecies"
                                item-text="caption"
                                item-value="id"
                                label="Anbauart" 
                                @change="changeCultivationSpecies"
                            >
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="12">
                        <v-switch
                            v-model="item.isLeased"
                            label="Gepachtet/Verpachtet"
                            @change="changeIsLeased"
                        >
                        </v-switch>
                    </v-col>
                </v-row>
                </v-container>
        </v-form>        
    
    </v-card>
</template>


<script>

    export default {

        props:{
            item: {
                required: false,
                type: Object,
                default: () => {
                    return  {
                        name: null,
                        area_ha: null,
                        area_ha_calculated: null,
                        cultivation_species: null,
                        isLeased: null,
                        polygon:[],         
                    }
                }
            },
            state: {
                required: false,
                type: Object,
                default: () => {
                    return  {
                        state:{
                            valid:false
                        }
                        // isEdit: false
                    }
                }
            },

            isEdit:{                
                required: true,
                default: false,
                type: Boolean
            },  
            
            isVisible:{                
                required: true,
                default: false,
                type: Boolean
            }            
        },
    
        data() {
            return {
                valid: false, 
                rules: {
                    required: v => !!v || 'This field is required',
                    minArea:v => ( v && v > 0 ) || "Fläche sollte größer sein als 0 ha",
                },
                selectCultivationSpecies: '',
                meta:{
                        wine:{
                            type:"",
                            date_of_cultivation: "", //(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                            row_distance: 2,
                            grapevine_distance: 1.2
                        }
                    }, 
                         
            }
    
        },

        computed:{
            cultivationSpecies(){
                return this.$store.state.app.availableCultivationSpecies
            },  
        },
    
        methods:{

            checkboxChanged(event){
                console.debug('checkboxChanged', event)
                this.item.area_ha = ""
            },

            changeCultivationSpecies(value){
                console.debug('changeCultivationSpecies')
                let obj = this.cultivationSpecies.find( item => item.id == value )
                if(obj.id == 1000)
                    this.item.meta.wine = JSON.parse(JSON.stringify(this.meta.wine))                
                
                this.item.cultivation_species_id = obj.id;
                this.$emit('cultivationspecieschanged', obj.id)                  
                
            },

            changeIsLeased(){
                console.debug('changeIsLeased')
                this.$emit('isleasedchanged', this.item.isLeased)  
            }

     
        },
    
        watch: { 
      
            item: function(newVal) { // watch it
                console.log('Prop changed: item', newVal, this.item)
                this.selectCultivationSpecies = this.item.cultivation_species_id;
            },
    
            valid: function(newVal) { // watch it
                console.log('Prop changed: valid', newVal, this.item)
                this.state.valid = this.valid; 
            },

            isEdit: function(newVal) { // watch it
                console.log('Prop changed: isEdit', newVal, this.item)
                this.selectCultivationSpecies = this.item.cultivation_species_id;
                if(!newVal)
                    this.$refs.form.reset() 
            }, 
            
            isVisible: function(newVal) { // watch it
                console.log('Prop changed: isVisible', newVal, this.item)
                this.selectCultivationSpecies = this.item.cultivation_species_id;
                if(!this.isEdit)
                    this.$refs.form.reset() 
            },             
        },
    
        mounted() {
            this.selectCultivationSpecies = this.item.cultivation_species_id;
            if(!this.isEdit)
                this.$refs.form.reset() 
        }
    }
</script>
    